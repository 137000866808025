import { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { TopUpAccountDialog } from 'domains/billing/dialogs';
import { useIsAllowedToTopUpCardAccount } from 'domains/billing/hooks';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import CustomBar from 'domains/organization/pages/DashboardPage/FinancialOverview/CustomBar';
import { isCreditOrgAvailableLimitLow } from 'domains/organization/utils';
import { Box, Button, InfoIcon, Tooltip, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  OrganizationAccountType,
  OrganizationProductType,
  OrganizationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { formatMoney } from 'services/utils';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';

const useGetNextPaymentDate = (orgId: string | null): string | null => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const [nextPaymentDate, setNextPaymentDate] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (!canUser('billing-payments-page:visit')) return;
      setNextPaymentDate(null);
      if (!orgId) return;
      try {
        const data = await api.getOrganizationBillPayments(orgId, 0, 1);
        if (!mounted.current) return;
        const nextPaymentDate = data.pendingPayments
          .concat(data.upcomingPayments)
          .map((payment) => payment.collectionDate)
          .filter((s) => !!s)
          .sort((a, b) => moment(a).diff(moment(b)))[0];
        if (nextPaymentDate) {
          setNextPaymentDate(moment(nextPaymentDate).format('D MMM YYYY'));
        }
      } catch (error) {
        logError(error);
      }
    })();
  }, [orgId]);

  return nextPaymentDate;
};

const SingleCreditAccountContent = () => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();
  const {
    state: { cardAccounts, defaultCardAccount, organization },
  } = useGlobalState();
  const isAllowedToTopUpCardAccount = useIsAllowedToTopUpCardAccount();
  const [isTopUpDialogOpen, setIsTopUpDialogOpen] = useState(false);
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    organization!.status,
    OrganizationStatus.onboardingRiskReview
  );
  const nextPaymentDate = useGetNextPaymentDate(
    defaultCardAccount!.accountType.value === OrganizationAccountType.credit &&
      organization!.productType === OrganizationProductType.MDESD &&
      didOrgPassOnboardingRisk
      ? organization!.id
      : null
  );
  const cardAccountsForTopUp = cardAccounts.filter(isAllowedToTopUpCardAccount);
  const balance = cardAccounts[0].balance.value;
  const availableLimit = cardAccounts[0].availableLimit.value;
  const limit = cardAccounts[0].creditLimit.value;

  const getCoverage = () => {
    return Math.round((availableLimit.value / limit.value) * 100) || 0;
  };

  const renderBalance = () => {
    const value = didOrgPassOnboardingRisk
      ? formatMoney(
          { value: Math.abs(balance.value), currency: limit.currency },
          i18n.language,
          { currencyDisplay }
        )
      : '-';
    return balance.value > 0
      ? t('dashboardPage.prefundedBalance', { value })
      : t('dashboardPage.outstandingBalance', { value });
  };

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
        <Typography variant="subtitle1" flexGrow={1}>
          {t('dashboardPage.availableFundsAndLimit')}
        </Typography>
        {[
          OrganizationProductType.MDESD,
          OrganizationProductType.CCROY,
        ].includes(organization!.productType) &&
          didOrgPassOnboardingRisk && (
            <>
              {nextPaymentDate && (
                <Typography variant="caption2" color="text.secondary" mx={1}>
                  <Trans
                    i18nKey="dashboardPage.nextPaymentDate"
                    values={{ date: nextPaymentDate }}
                  />
                </Typography>
              )}
              <Tooltip title={t('dashboardPage.topUpAccountTooltip')}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setIsTopUpDialogOpen(true)}
                  data-intercom-target="top-up-account-button"
                  disabled={!cardAccountsForTopUp.length}
                >
                  {t('dashboardPage.topUpAccount')}
                </Button>
              </Tooltip>
            </>
          )}
      </Box>

      <Box mb={2}>
        <Typography
          variant="caption2"
          component="div"
          color="text.secondary"
          mb={0.5}
        >
          {t('dashboardPage.available')}
        </Typography>
        <Box display="flex" alignItems="flex-end" mb={1}>
          <Typography
            variant="h5"
            color={
              isCreditOrgAvailableLimitLow(availableLimit, limit)
                ? 'warning.main'
                : 'text.primary'
            }
            data-intercom-target="financial-overview-widget-available-limit"
          >
            {didOrgPassOnboardingRisk ? (
              <FormatMoney value={availableLimit} />
            ) : (
              '-'
            )}
          </Typography>
          {didOrgPassOnboardingRisk && !!limit.value && (
            <>
              <Typography
                variant="caption"
                mr={0.5}
                mb="2px"
                ml={1}
                color="text.secondary"
              >
                <Trans
                  i18nKey="dashboardPage.coverage"
                  values={{ value: getCoverage() }}
                />
              </Typography>
              <Tooltip title={t('dashboardPage.coverageCreditTooltip')}>
                <InfoIcon
                  fontSize="small"
                  sx={{ mb: '2px', color: 'text.secondary' }}
                />
              </Tooltip>
            </>
          )}
        </Box>
        <CustomBar
          balance={didOrgPassOnboardingRisk ? balance : null}
          availableLimit={didOrgPassOnboardingRisk ? availableLimit : null}
          limit={didOrgPassOnboardingRisk ? limit : null}
        />
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Box display="flex" alignItems="center">
            <Typography variant="caption2" color="text.secondary">
              {t('dashboardPage.creditLimit', {
                value: didOrgPassOnboardingRisk
                  ? formatMoney(limit, i18n.language, { currencyDisplay })
                  : '-',
              })}
            </Typography>
            <Tooltip title={t('dashboardPage.creditLimitHint')}>
              <InfoIcon
                fontSize="small"
                sx={{
                  width: 16,
                  height: 16,
                  ml: 0.5,
                  color: 'text.secondary',
                }}
              />
            </Tooltip>
          </Box>
          <Typography
            variant="caption2"
            color="text.secondary"
            data-intercom-target="financial-overview-widget-outstanding-balance"
          >
            {renderBalance()}
          </Typography>
        </Box>
      </Box>

      <TopUpAccountDialog
        open={isTopUpDialogOpen}
        onClose={() => setIsTopUpDialogOpen(false)}
        visibleCardAccounts={cardAccountsForTopUp}
      />
    </>
  );
};

export default SingleCreditAccountContent;
