import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  AccountEntryMerchantCell,
  AmountCell,
  MerchantCell,
  TransactionAmountCell,
  TransactionMissingReceiptCell,
  TransactionReviewStatusIcon,
} from 'domains/transaction/components';
import {
  DataGridCell,
  DataGridSecondaryHeader,
  drawerPlaceholderColumn,
  GridColDef,
  Typography,
} from 'elements';
import {
  ExportAccountEntry,
  ExportFlowTransaction,
  ExportFormat,
  ReceiptDateExportMode,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import CardAccountCell from './CardAccountCell';
import ExportErrorCell from './ExportErrorCell';
import ProjectCell from './ProjectCell';
import ReceiptDateCell from './ReceiptDateCell';
import SubcategoryCell from './SubcategoryCell';
import TeamCell from './TeamCell';
import VatRateCell from './VatRateCell';

const useColumns = () => {
  const { datevExportEnabled, suppliersEnabled } = useFlags();
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const isDatevApiConnected =
    datevExportEnabled &&
    canUser('datev-sub-page:visit', accountingSettings) &&
    accountingSettings!.exportFormats.includes(ExportFormat.api);
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);

  const isBookingDateInUse = ![
    ReceiptDateExportMode.receiptDate,
    ReceiptDateExportMode.dynamic,
  ].includes(accountingSettings!.receiptDateExportMode);

  return useMemo(() => {
    const columns: GridColDef<ExportAccountEntry>[] = [
      {
        renderHeader: () => {
          return suppliersEnabled && accountingSettings?.supplierEnabled ? (
            <DataGridCell>
              {t('exportPage.table.merchant')}
              <DataGridSecondaryHeader>
                {t('exportPage.table.supplierAccountNumber')}
              </DataGridSecondaryHeader>
            </DataGridCell>
          ) : (
            <DataGridCell>{t('exportPage.table.merchant')}</DataGridCell>
          );
        },
        field: 'merchantName',
        sortable: false,
        flex: 1.8,
        minWidth: 60,
        renderCell: ({ row }) =>
          row.transactionInfo ? (
            <MerchantCell
              transaction={row.transactionInfo}
              showReceiptIcon
              showComment
              showCommentIcon
              showSupplierAccountNumber={
                suppliersEnabled && accountingSettings?.supplierEnabled
              }
              supplierAccountNumber={
                row.transactionInfo.transactionSupplierInfo?.accountNumber || ''
              }
            />
          ) : (
            <AccountEntryMerchantCell accountEntry={row} />
          ),
      },
      {
        headerName: '',
        field: 'receiptNeeded',
        sortable: false,
        flex: 0.2,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) =>
          row.transactionInfo && (
            <TransactionMissingReceiptCell transaction={row.transactionInfo} />
          ),
      },
      {
        headerName: '',
        field: 'exportError',
        sortable: false,
        width: 0.2,
        renderCell: ({ row }) =>
          row.transactionInfo && (
            <ExportErrorCell
              error={row.transactionInfo.exportError}
              isDatevApiConnected={isDatevApiConnected}
              isCodatConnected={isCodatConnected}
            />
          ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {isBookingDateInUse
              ? t('exportPage.table.bookingDate')
              : t('exportPage.table.receiptDate')}
            <DataGridSecondaryHeader>
              {isBookingDateInUse
                ? t('exportPage.table.transactionDate')
                : t('exportPage.table.bookingDate')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'createdAt',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.transactionInfo ? (
            <ReceiptDateCell transaction={row.transactionInfo} />
          ) : (
            <DataGridCell>
              {isBookingDateInUse
                ? moment(row.bookingDate).format('D MMM YYYY')
                : '-'}
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {isBookingDateInUse
                  ? '-'
                  : moment(row.bookingDate).format('D MMM YYYY')}
              </Typography>
            </DataGridCell>
          ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.exportDate')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.batchId')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'exportDateAndCounter',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => {
          if (!row.transactionInfo?.exportDateAndCounter) return null;
          const [date] = row.transactionInfo.exportDateAndCounter.split('-');
          return (
            <DataGridCell>
              {moment(date).format('D MMM YYYY')}
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {row.transactionInfo.exportDateAndCounter}
              </Typography>
            </DataGridCell>
          );
        },
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.subcategory')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.accountNumber')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'subcategoryStatus',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.transactionInfo && (
            <SubcategoryCell
              subcategoryStatus={row.transactionInfo.subcategoryStatus}
              subcategoryId={row.transactionInfo.subcategoryId}
              reviewStatus={row.transactionInfo.reviewStatus}
              reviewFlagReason={row.transactionInfo.reviewFlagReason}
            />
          ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.vatRate')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.vatRateSubtitle')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'vatRateStatus',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.transactionInfo && (
            <VatRateCell
              vatRateId={row.transactionInfo.vatRateId}
              vatRateStatus={row.transactionInfo.vatRateStatus}
              reviewStatus={row.transactionInfo.reviewStatus}
              reviewFlagReason={row.transactionInfo.reviewFlagReason}
            />
          ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.project')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.projectSubtitle')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'projectStatus',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.transactionInfo && (
            <ProjectCell
              projectName={row.transactionInfo.projectName}
              costUnit={row.transactionInfo.costUnit}
              projectStatus={row.transactionInfo.projectStatus}
              reviewStatus={row.transactionInfo.reviewStatus}
              reviewFlagReason={row.transactionInfo.reviewFlagReason}
            />
          ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.team')}
            {accountingSettings?.costCenterEnabled && (
              <DataGridSecondaryHeader>
                {t('exportPage.table.costCenter')}
              </DataGridSecondaryHeader>
            )}
          </DataGridCell>
        ),
        field: 'teamId',
        sortable: false,
        flex: 0.8,
        renderCell: ({ row }) =>
          row.transactionInfo && (
            <TeamCell
              teamName={row.transactionInfo.teamName}
              costCenter={row.transactionInfo.costCenter}
              reviewStatus={row.transactionInfo.reviewStatus}
              reviewFlagReason={row.transactionInfo.reviewFlagReason}
            />
          ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.account')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.accountCardNumber')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'cardAccountName',
        sortable: false,
        flex: 0.8,
        renderCell: ({ row }) => (
          <CardAccountCell
            cardAccountId={row.cardAccountId}
            cardAccountNumber={row.transactionInfo?.cardAccountNumber || null}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell sx={{ textAlign: 'right' }}>
            {t('exportPage.table.amount')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.fxAmount')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: ({ row }) =>
          row.transactionInfo ? (
            <TransactionAmountCell
              transaction={row.transactionInfo}
              bookingDate={row.transactionInfo.bookingDate}
            />
          ) : (
            <AmountCell accountEntry={row} />
          ),
      },
      {
        headerName: t('exportPage.table.review'),
        field: 'review',
        sortable: false,
        flex: 0.4,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) =>
          row.transactionInfo && (
            <TransactionReviewStatusIcon
              status={row.transactionInfo.reviewStatus}
            />
          ),
      },
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [i18n.language, datevExportEnabled, suppliersEnabled]);
};

// @deprecated - will be deleted together with accountEntriesOnExportPageEnabled ff
export const useColumnsOld = () => {
  const { datevExportEnabled, suppliersEnabled } = useFlags();
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const isDatevApiConnected =
    datevExportEnabled &&
    canUser('datev-sub-page:visit', accountingSettings) &&
    accountingSettings!.exportFormats.includes(ExportFormat.api);
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);

  const isBookingDateInUse = ![
    ReceiptDateExportMode.receiptDate,
    ReceiptDateExportMode.dynamic,
  ].includes(accountingSettings!.receiptDateExportMode);

  return useMemo(() => {
    const columns: GridColDef<ExportFlowTransaction>[] = [
      {
        renderHeader: () => {
          return suppliersEnabled && accountingSettings?.supplierEnabled ? (
            <DataGridCell>
              {t('exportPage.table.merchant')}
              <DataGridSecondaryHeader>
                {t('exportPage.table.supplierAccountNumber')}
              </DataGridSecondaryHeader>
            </DataGridCell>
          ) : (
            <DataGridCell>{t('exportPage.table.merchant')}</DataGridCell>
          );
        },
        field: 'merchantName',
        sortable: false,
        flex: 1.8,
        minWidth: 60,
        renderCell: ({ row }) => (
          <MerchantCell
            transaction={row}
            showReceiptIcon
            showComment
            showCommentIcon
            showSupplierAccountNumber={
              suppliersEnabled && accountingSettings?.supplierEnabled
            }
            supplierAccountNumber={
              row.transactionSupplierInfo?.accountNumber || ''
            }
          />
        ),
      },
      {
        headerName: '',
        field: 'receiptNeeded',
        sortable: false,
        flex: 0.2,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <TransactionMissingReceiptCell transaction={row} />
        ),
      },
      {
        headerName: '',
        field: 'exportError',
        sortable: false,
        width: 0.2,
        renderCell: ({ row }) => (
          <ExportErrorCell
            error={row.exportError}
            isDatevApiConnected={isDatevApiConnected}
            isCodatConnected={isCodatConnected}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {isBookingDateInUse
              ? t('exportPage.table.bookingDate')
              : t('exportPage.table.receiptDate')}
            <DataGridSecondaryHeader>
              {isBookingDateInUse
                ? t('exportPage.table.transactionDate')
                : t('exportPage.table.bookingDate')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'createdAt',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => <ReceiptDateCell transaction={row} />,
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.exportDate')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.batchId')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'exportDateAndCounter',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => {
          if (!row.exportDateAndCounter) return null;
          const [date] = row.exportDateAndCounter.split('-');
          return (
            <DataGridCell>
              {moment(date).format('D MMM YYYY')}
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {row.exportDateAndCounter}
              </Typography>
            </DataGridCell>
          );
        },
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.subcategory')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.accountNumber')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'subcategoryStatus',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <SubcategoryCell
            subcategoryStatus={row.subcategoryStatus}
            subcategoryId={row.subcategoryId}
            reviewStatus={row.reviewStatus}
            reviewFlagReason={row.reviewFlagReason}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.vatRate')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.vatRateSubtitle')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'vatRateStatus',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <VatRateCell
            vatRateId={row.vatRateId}
            vatRateStatus={row.vatRateStatus}
            reviewStatus={row.reviewStatus}
            reviewFlagReason={row.reviewFlagReason}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.project')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.projectSubtitle')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'projectStatus',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <ProjectCell
            projectStatus={row.projectStatus}
            projectName={row.projectName}
            costUnit={row.costUnit}
            reviewStatus={row.reviewStatus}
            reviewFlagReason={row.reviewFlagReason}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.team')}
            {accountingSettings?.costCenterEnabled && (
              <DataGridSecondaryHeader>
                {t('exportPage.table.costCenter')}
              </DataGridSecondaryHeader>
            )}
          </DataGridCell>
        ),
        field: 'teamId',
        sortable: false,
        flex: 0.8,
        renderCell: ({ row }) => (
          <TeamCell
            teamName={row.teamName}
            costCenter={row.costCenter}
            reviewStatus={row.reviewStatus}
            reviewFlagReason={row.reviewFlagReason}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('exportPage.table.account')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.accountCardNumber')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'cardAccountName',
        sortable: false,
        flex: 0.8,
        renderCell: ({ row }) => (
          <CardAccountCell
            cardAccountId={row.cardAccountId}
            cardAccountNumber={row.cardAccountNumber}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell sx={{ textAlign: 'right' }}>
            {t('exportPage.table.amount')}
            <DataGridSecondaryHeader>
              {t('exportPage.table.fxAmount')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        renderCell: ({ row }) => (
          <TransactionAmountCell
            transaction={row}
            bookingDate={row.bookingDate}
          />
        ),
      },
      {
        headerName: t('exportPage.table.review'),
        field: 'review',
        sortable: false,
        flex: 0.4,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <TransactionReviewStatusIcon status={row.reviewStatus} />
        ),
      },
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [i18n.language]);
};

export default useColumns;
