import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MISSING_TEAM_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CaretDownIcon,
  FormControl,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  Team,
  TeamCheck,
  TeamStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { TeamData } from './useFilters';

const MissinInputValue = '—';

type Value = TeamData['value'];

interface Props {
  value: Value;
  teamData: TeamData;
  onChange: (value: Value) => void;
}

interface State {
  isLoading: boolean;
  teams: (Team | typeof MISSING_TEAM_OPTION)[];
}

const TeamsFilter = ({ value, teamData, onChange }: Props) => {
  const isFetchAllowed = useRef(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    teams: [],
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { teams } = await api.getTeams({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue === MissinInputValue ? '' : inputValue.trim(),
        sortBy: '+name',
        status: TeamStatus.active,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        teams: [MISSING_TEAM_OPTION, ...teams],
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!isFetchAllowed.current) return;

    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-team"
        id="panel-team-header"
      >
        <Typography>{t('filters.team')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Autocomplete<
            Team | TeamCheck | typeof MISSING_TEAM_OPTION,
            false,
            false,
            false
          >
            onOpen={() => {
              if (!isFetchAllowed.current) {
                setState((prevState) => ({ ...prevState, isLoading: true }));
                getData();
                isFetchAllowed.current = true;
              }
            }}
            inputValue={inputValue}
            loading={state.isLoading}
            size="medium"
            onInputChange={(e, inputValue) => {
              setInputValue(inputValue);
            }}
            error={teamData.isError}
            value={value}
            onChange={(e, value) => {
              onChange(value);
            }}
            options={state.teams}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => {
              if (
                option === MISSING_TEAM_OPTION ||
                value === MISSING_TEAM_OPTION
              )
                return value === option;
              return option?.id === value?.id;
            }}
            getOptionLabel={(option) => {
              if (!option) return '';
              if (option === MISSING_TEAM_OPTION) return MissinInputValue;
              return option.name;
            }}
            renderOption={(props, option) => {
              if (!option) return '';
              if (option === MISSING_TEAM_OPTION)
                return (
                  <li {...props} key={option}>
                    —
                  </li>
                );
              return (
                <li {...props} key={option.id}>
                  <Box
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {accountingSettings?.costCenterEnabled &&
                    option.costCenter ? (
                      <>
                        {option.name} ({option.costCenter})
                      </>
                    ) : (
                      option.name
                    )}
                  </Box>
                </li>
              );
            }}
            placeholder={
              teamData.isError ? t('filters.notFound') : t('filters.team')
            }
            loadingText={t('common.loading')}
            noOptionsText={t('common.nothingFound')}
            searchIcon={false}
          />
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default TeamsFilter;
