import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import { CodatEntitySearchSelect } from 'domains/settings/components';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import {
  CardAccountSettings,
  CodatCompanyBankAccount,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  accountToEdit: CardAccountSettings | null;
  onClose: () => void;
  onSuccess: () => void;
}

interface FormValues {
  cardAccountId: string;
  pliantCreditCardAccount: string;
  defaultAccount: boolean;
  // Codat related bank account
  bankAccount: CodatCompanyBankAccount | null;
}

const AssignEditAccountNumberDialog = ({
  accountToEdit,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings, cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      cardAccountId: cardAccounts[0].id,
      bankAccount: accountToEdit?.accountReferenceId
        ? {
            id: accountToEdit.accountReferenceId,
            name: accountToEdit.pliantCreditCardAccount,
          }
        : null,
      pliantCreditCardAccount: accountToEdit?.pliantCreditCardAccount || '',
      defaultAccount: accountToEdit?.defaultAccount || false,
    },
    validate(values) {
      const errors: FormikErrors<FormValues> = {};

      if (isCodatConnected) return;

      const generalLedgerAccLen = accountingSettings!
        .generalLedgerAccountLength;
      if (!generalLedgerAccLen) return;

      const pliantCreditCardAccLen = values.pliantCreditCardAccount.length;
      if (
        pliantCreditCardAccLen &&
        (pliantCreditCardAccLen < generalLedgerAccLen ||
          pliantCreditCardAccLen > generalLedgerAccLen + 1)
      ) {
        errors.pliantCreditCardAccount = t(
          'assignEditAccountNumberDialog.strLenIsNotBetweenError',
          {
            min: generalLedgerAccLen,
            max: generalLedgerAccLen + 1,
          }
        );
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      const payload = isCodatConnected
        ? {
            pliantCreditCardAccount: values.bankAccount?.name ?? '',
          }
        : {
            pliantCreditCardAccount: values.pliantCreditCardAccount.trim(),
          };
      try {
        await api.updateCardAccountSettings(
          accountToEdit
            ? accountToEdit.cardAccountId
            : formik.values.cardAccountId,
          {
            organizationId: organization!.id,
            accountReferenceId: values.bankAccount?.id ?? '',
            ...payload,
          }
        );

        const accountingSettings = await api.getAccountingSettings(
          organization!.id
        );

        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings },
        });

        if (!mounted.current) return;
        onSuccess();
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField =
    !formik.values.bankAccount && !formik.values.pliantCreditCardAccount.trim();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {accountToEdit
          ? t('assignEditAccountNumberDialog.titleEdit')
          : t('assignEditAccountNumberDialog.titleAssign')}
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          id="update-card-acc-form"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {accountToEdit ? (
                <TextField
                  disabled
                  label={t(
                    'assignEditAccountNumberDialog.cardAccountNameLabel'
                  )}
                  name="cardAccountName"
                  value={getCardAccountName(accountToEdit.cardAccountId)}
                />
              ) : (
                <FormControl fullWidth>
                  <InputLabel>
                    {t('assignEditAccountNumberDialog.cardAccountNameLabel')}
                  </InputLabel>
                  <Select<string>
                    renderValue={(value) => {
                      const selectedAccount = cardAccounts.find(
                        (account) => account.id === value
                      );
                      if (!selectedAccount) return '';
                      return getCardAccountName(selectedAccount);
                    }}
                    {...formik.getFieldProps('cardAccountId')}
                  >
                    {cardAccounts.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {getCardAccountName(account)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12}>
              {isCodatConnected ? (
                <CodatEntitySearchSelect<CodatCompanyBankAccount>
                  label={
                    <>
                      {t(
                        'assignEditAccountNumberDialog.pliantCreditCardAccountCodatLabel',
                        {
                          partnerName,
                        }
                      )}
                      <InputLabelTooltipIcon
                        title={t(
                          'assignEditAccountNumberDialog.pliantCreditCardAccountCodatTitle',
                          {
                            partnerName,
                          }
                        )}
                      />
                    </>
                  }
                  placeholder={t(
                    'assignEditAccountNumberDialog.bankAccountPlaceholder'
                  )}
                  value={formik.values.bankAccount}
                  onChange={(value) =>
                    formik.setFieldValue('bankAccount', value)
                  }
                  getPageableList={({ q, page, limit }) =>
                    api
                      .getCodatCompanyBankAccounts(organization!.id, {
                        name: q || undefined,
                        page,
                        limit,
                      })
                      .then((data) => ({
                        items: data.bankAccounts,
                        hasNextPage: data.hasNextPage,
                      }))
                  }
                />
              ) : (
                <NumberFormat
                  label={t(
                    'assignEditAccountNumberDialog.pliantCreditCardAccountLabel'
                  )}
                  customInput={TextField}
                  disabled={formik.isSubmitting}
                  allowLeadingZeros={true}
                  allowNegative={false}
                  decimalScale={0}
                  error={!!formik.errors.pliantCreditCardAccount}
                  helperText={formik.errors.pliantCreditCardAccount}
                  {...formik.getFieldProps('pliantCreditCardAccount')}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={hasEmptyField || formik.isSubmitting || !formik.dirty}
          type="submit"
          form="update-card-acc-form"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AssignEditAccountNumberDialog);
