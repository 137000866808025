import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoData from 'components/NoData';
import { useGlobalState } from 'context/GlobalState';
import { BillPaymentItem } from 'domains/billing/components';
import {
  PayoutPaymentDialog,
  TopUpAccountDialog,
} from 'domains/billing/dialogs';
import {
  useDownloadBillingFileFromQueryParams,
  useIsAllowedToTopUpCardAccount,
  useIsPayoutFromCardAccountAllowed,
} from 'domains/billing/hooks';
import {
  ArrowLineDownIcon,
  Box,
  Button,
  FileTextIcon,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  WithdrawIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import { PageContent, PageHeader, PageTitle, PageTitleActions } from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  OrganizationAccountStatementPayment,
  OrganizationAccountType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

interface State {
  isLoading: boolean;
  upcomingPayments: OrganizationAccountStatementPayment[];
  pendingPayments: OrganizationAccountStatementPayment[];
  pastPayments: OrganizationAccountStatementPayment[];
  hasNextPage: boolean;
  isLoadingMore: boolean;
  isTopUpAccountDialogOpen: boolean;
  isPayoutPaymentDialogOpen: boolean;
}

const BillingPaymentsPage = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const showPageError = useShowPageError();
  const mounted = useMounted();
  const isPayoutFromCardAccountAllowed = useIsPayoutFromCardAccountAllowed();
  const isAllowedToTopUpCardAccount = useIsAllowedToTopUpCardAccount();
  const {
    state: { organization, cardAccounts },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    upcomingPayments: [],
    pendingPayments: [],
    pastPayments: [],
    hasNextPage: false,
    isLoadingMore: false,
    isTopUpAccountDialogOpen: false,
    isPayoutPaymentDialogOpen: false,
  });
  const pageRef = useRef(0);
  const lastRequestId = useRef<number>(0);
  useDownloadBillingFileFromQueryParams();

  const arePastPaymentsVisible = cardAccounts.every(
    (account) => account.accountType.value === OrganizationAccountType.prefunded
  );

  const allCardAccountsForPayout = cardAccounts.filter(
    isPayoutFromCardAccountAllowed
  );
  const cardAccountsForPayout = allCardAccountsForPayout.filter(
    (cardAccount) => cardAccount.balance.value.value > 0
  );

  const cardAccountsForTopUp = cardAccounts.filter(isAllowedToTopUpCardAccount);

  const getData = async (page: number, isLoadMore = false) => {
    try {
      const currRequestId = Date.now();
      lastRequestId.current = currRequestId;
      setState((prevState) => {
        return isLoadMore
          ? { ...prevState, isLoadingMore: true }
          : { ...prevState, isLoading: true };
      });
      const limit = arePastPaymentsVisible ? DEFAULT_PAGE_LIMIT : 1;
      const {
        upcomingPayments,
        pendingPayments,
        organizationPastBillPaymentsPageableResponse: {
          pastPayments,
          hasNextPage,
        },
      } = await api.getOrganizationBillPayments(organization!.id, page, limit);
      if (!mounted.current || lastRequestId.current !== currRequestId) return;
      setState((prevState) => {
        const allPastPayments = isLoadMore
          ? [...prevState.pastPayments, ...pastPayments]
          : pastPayments;
        return {
          ...prevState,
          isLoading: false,
          isLoadingMore: false,
          upcomingPayments,
          pendingPayments,
          pastPayments: arePastPaymentsVisible ? allPastPayments : [],
          hasNextPage: arePastPaymentsVisible ? hasNextPage : false,
        };
      });
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const refreshPage = () => {
    pageRef.current = 0;
    getData(pageRef.current);
  };

  const loadMoreItems = useCallback(() => {
    if (!state.hasNextPage || state.isLoading || state.isLoadingMore) return;
    pageRef.current++;
    getData(pageRef.current, true);
  }, [state.hasNextPage, state.isLoading, state.isLoadingMore]);

  useEffect(() => {
    refreshPage();
  }, []);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const LOADER_ITEM_HEIGHT = 100;

      const listboxNode = e.currentTarget;
      if (
        listboxNode.scrollTop + listboxNode.clientHeight >=
        listboxNode.scrollHeight - LOADER_ITEM_HEIGHT
      ) {
        loadMoreItems();
      }
    },
    [loadMoreItems]
  );

  return (
    <>
      <PageHeader>
        <PageTitle title={t('billingPage.payments')}>
          <PageTitleActions>
            {!!allCardAccountsForPayout.length && (
              <Button
                variant="outlined"
                startIcon={<WithdrawIcon />}
                disabled={!cardAccountsForPayout.length}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isPayoutPaymentDialogOpen: true,
                  }));
                }}
              >
                {t('billingPage.withdraw')}
              </Button>
            )}
            {!!cardAccountsForTopUp.length && (
              <Button
                startIcon={<ArrowLineDownIcon />}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isTopUpAccountDialogOpen: true,
                  }))
                }
              >
                {t('billingPage.topUpAccount')}
              </Button>
            )}
          </PageTitleActions>
        </PageTitle>
      </PageHeader>
      <PageContent onScroll={handleScroll}>
        {!!state.upcomingPayments.length && (
          <Table sx={{ mb: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>
                  {t('billingPage.upcomingTitle')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.upcomingPayments.map((payment) => (
                <BillPaymentItem
                  data={payment}
                  key={payment.id}
                  isWide
                  onRefresh={refreshPage}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {!!state.pendingPayments.length && (
          <Table sx={{ mb: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>
                  {t('billingPage.pendingTitle')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.pendingPayments.map((payment) => (
                <BillPaymentItem
                  data={payment}
                  key={payment.id}
                  isWide
                  onRefresh={refreshPage}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {!!state.pastPayments.length && (
          <Table sx={{ mb: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>{t('billingPage.pastTitle')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.pastPayments.map((payment) => (
                <BillPaymentItem
                  data={payment}
                  key={payment.id}
                  isWide
                  onRefresh={refreshPage}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {state.hasNextPage && (
          <Box sx={{ height: 60, position: `relative` }}>
            <LoaderWithOverlay loading />
          </Box>
        )}
        {!state.isLoading &&
          !state.upcomingPayments.length &&
          !state.pendingPayments.length &&
          !state.pastPayments.length && (
            <NoData icon={<FileTextIcon />} label={t('billingPage.noData')} />
          )}
        <LoaderWithOverlay loading={state.isLoading} />
      </PageContent>

      <TopUpAccountDialog
        open={state.isTopUpAccountDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isTopUpAccountDialogOpen: false,
          }))
        }
        visibleCardAccounts={cardAccountsForTopUp}
      />
      <PayoutPaymentDialog
        open={state.isPayoutPaymentDialogOpen}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            isPayoutPaymentDialogOpen: false,
          }));
        }}
        onSuccess={() => {
          refreshPage();
          setState((prevState) => ({
            ...prevState,
            isPayoutPaymentDialogOpen: false,
          }));
        }}
        visibleCardAccounts={cardAccountsForPayout}
      />
    </>
  );
};

export default withPageConfig(BillingPaymentsPage, {
  permission: 'billing-payments-page:visit',
});
