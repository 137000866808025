import { Fragment, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  merchantCategories,
  MerchantCategory,
  OrganizationMerchant,
  Subcategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { getPath } from 'services/utils/getPath';

interface State {
  isLoading: boolean;
  subcategories: Subcategory[];
  subcategory: Subcategory | null;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (value: OrganizationMerchant['subcategoryResponse']) => void;
  subcategoryResponse: OrganizationMerchant['subcategoryResponse'];
  merchantId: string;
}

const ChangeSubcategoryDialog = ({
  subcategoryResponse,
  onSuccess,
  merchantId,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    subcategories: [],
    subcategory: null,
  });

  const getSubcategories = async () => {
    try {
      const { subcategories } = await api.getSubcategories(organization!.id);
      dispatch({ type: 'SET_ORGANIZATION_DATA', payload: { subcategories } });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        subcategories: subcategories,
        subcategory:
          subcategories.find((item) => item.id === subcategoryResponse?.id) ||
          null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getSubcategories();
  }, []);

  const updateSubCategory = async (): Promise<
    OrganizationMerchant['subcategoryResponse']
  > => {
    if (state.subcategory) {
      await api.assignSubcategoryToMerchant(
        organization!.id,
        state.subcategory.id,
        merchantId
      );
      return {
        id: state.subcategory.id,
        name: state.subcategory.name,
        number: state.subcategory.number,
      };
    }
    await api.unassignSubcategoryFromMerchant(
      organization!.id,
      subcategoryResponse!.id,
      merchantId
    );
    return null;
  };

  const handleSubmit = async () => {
    if (state.isLoading) return;
    if ((state.subcategory?.id || '') === (subcategoryResponse?.id || ''))
      return props.onClose();

    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const updatedSubcategoryResponse = await updateSubCategory();
      onSuccess(updatedSubcategoryResponse);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const options = useMemo(() => {
    const visibleItems = state.subcategories.filter(
      (item) =>
        item.status === AccountingItemStatus.active ||
        item.id === subcategoryResponse?.id
    );
    const sortedItems = sortBy(visibleItems, (v) => v.name.toLowerCase());
    return merchantCategories
      .map((category) => {
        return sortedItems.filter((item) => item.category === category);
      })
      .flat();
  }, [state.subcategories]);

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('subcategorySelectDialog.header')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={4}>
          <Trans
            i18nKey="subcategorySelectDialog.description"
            components={{
              b: <b />,
              categoriesLink: (
                <Link
                  component={RouterLink}
                  to={generatePath(getPath('settingsCategories'), {
                    orgId: organization!.id,
                  })}
                />
              ),
            }}
          />
        </Typography>
        <Autocomplete<Subcategory, false, false, false>
          disabled={state.isLoading}
          value={state.subcategory}
          onChange={(_, value) =>
            setState((prevState) => ({ ...prevState, subcategory: value }))
          }
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) => {
              const name = option.name.toLowerCase();
              const glNumber = option.number?.toLowerCase();
              const val = inputValue?.toLowerCase();
              return name.includes(val) || glNumber?.includes(val);
            })
          }
          groupBy={(option) => option.category}
          getOptionLabel={(option) =>
            option.name + (option.number ? ` (${option.number})` : '')
          }
          noOptionsText={t('common.nothingFound')}
          renderGroup={(params) => (
            <Fragment key={params.group}>
              <Box
                component="li"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  px: 2,
                  py: 1,
                  '& svg': { flexShrink: 0 },
                }}
              >
                <MerchantCategoryIcon
                  category={params.group as MerchantCategory}
                />
                <Box ml={1}>{t(`merchantCategories.${params.group}`)}</Box>
              </Box>
              {params.children}
            </Fragment>
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              <Box width={16} ml={1} flexShrink={0} />
              {option.name + (option.number ? ` (${option.number})` : '')}
            </Box>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={state.isLoading} onClick={handleSubmit}>
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeSubcategoryDialog);
