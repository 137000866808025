import { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  OrganizationOnboardingGroupPage,
  OrganizationOnboardingPage,
  OrganizationOnboardingSectionPage,
  OrganizationOnboardingTaskPage,
} from 'domains/onboarding/pages';
import { getUrlFromOnboardingKeyType } from 'domains/onboarding/utils';
import { LoaderWithOverlay } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import withOnboardingRedirection from './withOnboardingRedirection';

const OrganizationOnboardingRoutes = () => {
  const { path } = useRouteMatch();
  const showPageError = useShowPageError();
  const { useGetOnboardingSections } = useTanstackQuery();
  const { data, isInitialLoading, error } = useGetOnboardingSections();

  useEffect(() => {
    if (error) {
      logError(error);
      showPageError();
    }
  }, [error]);

  const sectionPaths = useMemo(
    () =>
      data?.sections.map(
        (section) => `${path}/${getUrlFromOnboardingKeyType(section.type)}`
      ) || [],
    [data?.sections]
  );

  if (isInitialLoading) return <LoaderWithOverlay loading />;

  return (
    <Switch>
      <Route path={sectionPaths}>
        <Switch>
          <Route
            path={`${path}/:sectionKey/task/:taskId`}
            component={OrganizationOnboardingTaskPage}
          />
          <Route
            path={`${path}/:sectionKey/group/:groupId/task/:taskId`}
            component={OrganizationOnboardingTaskPage}
          />
          <Route
            path={`${path}/:sectionKey/group/:groupId`}
            component={OrganizationOnboardingGroupPage}
          />
          <Route
            exact
            path={`${path}/:sectionKey`}
            component={OrganizationOnboardingSectionPage}
          />
          <Redirect to={path} />
        </Switch>
      </Route>
      <Route
        exact
        path={path}
        render={(props) => (
          <OrganizationOnboardingPage
            sections={data?.sections || null}
            {...props}
          />
        )}
      />
      <Redirect to={path} />
    </Switch>
  );
};

export default withPageConfig(
  withOnboardingRedirection(OrganizationOnboardingRoutes),
  {
    permission: 'org-onboarding-page:visit',
  }
);
