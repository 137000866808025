import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CaretDownIcon,
  FormControl,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  VatRate,
  VatRateStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { VatRateData } from './useFilters';

const MissinInputValue = '—';

type Value = VatRateData['value'];

interface Props {
  value: Value;
  vatRateData: VatRateData;
  onChange: (value: Value) => void;
}

interface State {
  isLoading: boolean;
  vatRates: (VatRateStatus.missing | VatRate)[];
}

const VatRateFilter = ({ value, vatRateData, onChange }: Props) => {
  const isFetchAllowed = useRef(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    vatRates: [],
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { vatRates } = await api.getVatRates({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue === MissinInputValue ? '' : inputValue.trim(),
        sortBy: '+name',
        status: AccountingItemStatus.active,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        vatRates: [VatRateStatus.missing, ...vatRates],
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!isFetchAllowed.current) return;

    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-vatRate"
        id="panel-vatRate-header"
      >
        <Typography>{t('filters.vatRate')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Autocomplete<VatRate | VatRateStatus.missing, false, false, false>
            onOpen={() => {
              if (!isFetchAllowed.current) {
                setState((prevState) => ({ ...prevState, isLoading: true }));
                getData();
                isFetchAllowed.current = true;
              }
            }}
            inputValue={inputValue}
            loading={state.isLoading}
            size="medium"
            onInputChange={(e, inputValue) => {
              setInputValue(inputValue);
            }}
            error={vatRateData.isError}
            value={value}
            onChange={(e, value) => onChange(value)}
            options={state.vatRates}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => {
              if (
                option === VatRateStatus.missing ||
                value === VatRateStatus.missing
              )
                return value === option;
              return option?.id === value?.id;
            }}
            getOptionLabel={(option) => {
              if (!option) return '';
              if (option === VatRateStatus.missing) return MissinInputValue;
              return option.name;
            }}
            renderOption={(props, option) => {
              if (!option) return '';
              if (option === VatRateStatus.missing)
                return (
                  <li {...props} key={option}>
                    —
                  </li>
                );
              return (
                <li {...props} key={option.id}>
                  <Box
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {option.name} (
                    <FormatPercent
                      value={option.rate}
                      options={{
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }}
                    />
                    )
                  </Box>
                </li>
              );
            }}
            placeholder={
              vatRateData.isError ? t('filters.notFound') : t('filters.vatRate')
            }
            loadingText={t('common.loading')}
            noOptionsText={t('common.nothingFound')}
            searchIcon={false}
          />
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default VatRateFilter;
