import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { FormLabel, Grid, Switch } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isConfirmDialogOpen: boolean;
  isCreditApplicationEnabled: boolean;
  isLoading: boolean;
}

const CreditApplicationEnabledToggle = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isConfirmDialogOpen: false,
    isCreditApplicationEnabled: false,
    isLoading: true,
  });

  const fetchCreditApplicationEnabled = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { creditApplicationEnabled } = await api.getAdditionalInformations(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isCreditApplicationEnabled: creditApplicationEnabled,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    fetchCreditApplicationEnabled();
  }, []);

  const updateCreditApplicationEnabled = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const {
        creditApplicationEnabled,
      } = await api.updateCreditApplicationEnabled(
        organization!.id,
        !state.isCreditApplicationEnabled
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isCreditApplicationEnabled: creditApplicationEnabled,
        isConfirmDialogOpen: false,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item display="flex" alignItems="center">
          <FormLabel component="span">
            {t('int.creditApplicationEnabledToggle.label')}
          </FormLabel>
        </Grid>
        <Grid item>
          <Switch
            checked={state.isCreditApplicationEnabled}
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                isConfirmDialogOpen: true,
              }))
            }
            size="medium"
            disabled={state.isLoading}
          />
        </Grid>
      </Grid>

      <ConfirmDialog
        loading={state.isLoading}
        onSuccess={updateCreditApplicationEnabled}
        open={state.isConfirmDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmDialogOpen: false,
          }))
        }
        description={t(
          state.isCreditApplicationEnabled
            ? 'int.creditApplicationEnabledToggle.creditApplicationDisabledDescription'
            : 'int.creditApplicationEnabledToggle.creditApplicationEnabledDescription'
        )}
        title={t(
          state.isCreditApplicationEnabled
            ? 'int.creditApplicationEnabledToggle.creditApplicationDisabledTitle'
            : 'int.creditApplicationEnabledToggle.creditApplicationEnabledTitle'
        )}
      />
    </>
  );
};

export default CreditApplicationEnabledToggle;
