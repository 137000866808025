import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  ProjectFilterChip,
  TeamFilterChip,
} from 'domains/transaction/components';
import { ProjectsData, TeamsData } from 'domains/transaction/hooks';
import { QueryParams as ChTeamsTransactionsPageQueryParams } from 'domains/transaction/pages/CardholderTeamTransactionsPage';
import { QueryParams as TransactionsPageQueryParams } from 'domains/transaction/pages/TransactionsPage';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';
import { NONE_VALUE } from 'services/constants';

interface Props {
  params: TransactionsPageQueryParams | ChTeamsTransactionsPageQueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  teamData: TeamsData;
  projectData: ProjectsData;
}

const FilterChips = ({
  params,
  selectedFiltersCount,
  setParam,
  teamData,
  projectData,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { cardAccounts, featureModules },
  } = useGlobalState();

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((item) => t(`transactionStatuses.${item}`))
      .join(', ');
  }, [params.status]);

  const txSimpleTypeChipTooltip = useMemo(() => {
    if (params.type.length < 2) return '';
    return params.type
      .map((item) => t(`transactionSimpleTypes.${item}`))
      .join(', ');
  }, [params.type]);

  const categoryChipTooltip = useMemo(() => {
    if (params.category.length < 2) return '';
    return params.category
      .map((item) => t(`merchantCategories.${item}`))
      .join(', ');
  }, [params.category]);

  const periodChipText = useMemo(() => {
    if (!params.fromDate && !params.toDate) return '';
    const array = [];
    if (params.fromDate)
      array.push(moment(params.fromDate).format('D MMM YYYY'));
    if (params.toDate) array.push(moment(params.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [params.fromDate, params.toDate]);

  const reviewStatusChipText = useMemo(() => {
    if (!params.reviewStatus) return '';
    if (params.reviewStatus === NONE_VALUE) return t(`common.none`);
    return t(`transactionReviewStatus.${params.reviewStatus}`);
  }, [params.reviewStatus]);

  const cardAccountsChipText = useMemo(
    () =>
      cardAccounts.find((account) => account.id === params.cardAccountId)?.name
        ?.value || '',
    [params.cardAccountId, cardAccounts]
  );

  if (!selectedFiltersCount) return null;

  return (
    <PageFilterChips>
      {!!params.status.length && (
        <Tooltip title={statusChipTooltip}>
          <Chip
            label={getFilterChipText(
              t(`transactionStatuses.${params.status[0]}`),
              params.status.length
            )}
            onDelete={() => setParam('status', [])}
          />
        </Tooltip>
      )}

      {!!params.type.length && (
        <Tooltip title={txSimpleTypeChipTooltip}>
          <Chip
            label={getFilterChipText(
              t(`transactionSimpleTypes.${params.type[0]}`),
              params.type.length
            )}
            onDelete={() => setParam('type', [])}
          />
        </Tooltip>
      )}

      {(params.fromDate || params.toDate) && (
        <Chip
          label={periodChipText}
          onDelete={() =>
            setParam([
              ['toDate', ''],
              ['fromDate', ''],
            ])
          }
        />
      )}

      {cardAccounts.length > 1 && params.cardAccountId && (
        <Chip
          label={getFilterChipText(
            cardAccountsChipText,
            1,
            t('filters.account')
          )}
          onDelete={() => setParam('cardAccountId', '')}
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && !!params.receipt && (
        <Chip
          label={getFilterChipText(
            t(`transactionReceiptStatuses.${params.receipt}`),
            1,
            t('filters.receipts')
          )}
          onDelete={() => setParam('receipt', '')}
        />
      )}

      {!!params.category.length && (
        <Tooltip title={categoryChipTooltip}>
          <Chip
            label={getFilterChipText(
              t(`merchantCategories.${params.category[0]}`),
              params.category.length
            )}
            onDelete={() => setParam('category', [])}
          />
        </Tooltip>
      )}

      <TeamFilterChip
        teamData={teamData}
        teamsIdsParams={params.teamId}
        setParam={setParam}
      />

      <ProjectFilterChip
        projectData={projectData}
        projectIdsParams={params.projectIds}
        setParam={setParam}
      />

      {params.reviewStatus && (
        <Chip
          label={reviewStatusChipText}
          onDelete={() =>
            setParam([
              ['reviewStatus', ''],
              ['flagReason', ''],
            ])
          }
        />
      )}

      {params.flagReason && (
        <Chip
          label={t(`reviewFlagReasons.${params.flagReason}`)}
          onDelete={() => setParam('flagReason', '')}
        />
      )}
    </PageFilterChips>
  );
};

export default FilterChips;
