import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from 'elements';
import { OrganizationAccountType } from 'services/constants';

interface Props extends Omit<SelectProps<string>, 'renderValue' | 'labelId'> {
  label: string;
  cardAccountIds: string[];
}

const CardAccountSelect = ({ label, cardAccountIds, ...props }: Props) => {
  const {
    state: { cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();

  return (
    <FormControl fullWidth>
      <InputLabel id="card-account-id-select-label">{label}</InputLabel>
      <Select<string>
        {...props}
        renderValue={(selected) => {
          const account = cardAccounts.find((item) => item.id === selected);

          return account ? getCardAccountName(account) : null;
        }}
        labelId="card-account-id-select-label"
        MenuProps={{ PaperProps: { sx: { maxWidth: 500 } } }}
      >
        {cardAccountIds.map((accountId) => {
          const account = cardAccounts.find((item) => item.id === accountId);

          if (!account) return null;

          return (
            <MenuItem key={accountId} value={accountId}>
              <ListItemText
                primary={getCardAccountName(account)}
                primaryTypographyProps={{ noWrap: true }}
                secondary={
                  account.accountType.value ===
                  OrganizationAccountType.prefunded ? (
                    <FormatMoney value={account.balance.value} />
                  ) : (
                    <>
                      <FormatMoney value={account.availableLimit.value} /> /{' '}
                      <FormatMoney value={account.creditLimit.value} />
                    </>
                  )
                }
                secondaryTypographyProps={{ noWrap: true }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CardAccountSelect;
