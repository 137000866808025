import { useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  ActiveFilterValue,
  StatusFilterSelect,
} from 'domains/settings/components';
import { AddNewItemMenu } from 'domains/settings/components/AddNewItemMenu';
import {
  AddSubCategoryDialog,
  CodatSyncSeparatedDialog,
  EditSubCategoryDialog,
  SyncNameEnum,
  UploadGLAccountsDialog,
} from 'domains/settings/dialogs';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import withTabPermission from 'domains/settings/pages/AccountingPage/withTabPermission';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Badge,
  Box,
  Button,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageSearchInput } from 'layout';
import {
  AccountingItemStatus,
  AccountingSystem,
  CodatMappingOptionsSummary,
  merchantCategories,
  MerchantCategory,
  Subcategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import { getGenericErrorMsg, getPath } from 'services/utils';
import SubcategoriesTable from './SubcategoriesTable';

interface State {
  isLoading: boolean;
  activeFilter: ActiveFilterValue | 'all';
  isAddSubcategoryDialogOpen: boolean;
  isEditSubcategoryDialogOpen: boolean;
  isUploadSubcategoryDialogOpen: boolean;
  editableSubcategory: Subcategory | null;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
  search: string;
  codatSyncDialogOpen: boolean;
}

const CategoriesSubPage = () => {
  const history = useHistory();
  const location = useLocation<{ isCodatFlow: boolean }>();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, subcategories, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    activeFilter: AccountingItemStatus.active,
    isAddSubcategoryDialogOpen: false,
    isEditSubcategoryDialogOpen: false,
    isUploadSubcategoryDialogOpen: false,
    editableSubcategory: null,
    codatMappingOptionsSummary: null,
    search: '',
    codatSyncDialogOpen: false,
  });

  useEffect(() => {
    if (location.state?.isCodatFlow && canUser('codat-sync:change')) {
      setState((prevState) => ({
        ...prevState,
        codatSyncDialogOpen: true,
      }));
      history.replace({ state: null });
    }
  }, [location.state]);

  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);
  const areItemsReadOnly =
    (accountingSettings!.accountingSystem === AccountingSystem.lexOffice &&
      accountingSettings!.useAccountingExport) ||
    isCodatConnected;

  const filteredCategories = useMemo(
    () =>
      sortBy(subcategories || [], (v) => v.name.toLowerCase()).filter(
        (item) =>
          state.activeFilter === 'all' || item.status === state.activeFilter
      ),
    [subcategories, state.activeFilter]
  );

  const visibleCategories = useMemo(
    () =>
      filteredCategories.filter((item) =>
        item.name.toLowerCase().includes(state.search.trim().toLowerCase())
      ),
    [filteredCategories, state.search]
  );

  const subcategoriesByCategories: {
    [key in MerchantCategory]?: Subcategory[];
  } = useMemo(() => {
    return merchantCategories.reduce(
      (prev, category) => ({
        ...prev,
        [category]: visibleCategories.filter(
          (item) => item.category === category
        ),
      }),
      {}
    );
  }, [visibleCategories, state.activeFilter]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        { subcategories },
        codatMappingOptionsSummary,
      ] = await Promise.all([
        api.getSubcategories(organization!.id),
        isCodatConnected && canUser('codat-sync:view')
          ? api.getCodatMappingOptionsSummary(organization!.id)
          : null,
      ]);
      dispatch({ type: 'SET_ORGANIZATION_DATA', payload: { subcategories } });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        codatMappingOptionsSummary,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const noSearchResults = !visibleCategories.length && state.search;

  const renderTitle = () => {
    if (isCodatConnected)
      return (
        <Trans
          i18nKey="categoriesSubPage.codatTitle"
          values={{ name: accountingSettings!.accountingSystemName }}
          components={{
            a: (
              <Link
                to={generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
        />
      );
    return t('categoriesSubPage.title');
  };

  const renderCustomButton = () => {
    if (isCodatConnected && state.codatMappingOptionsSummary) {
      if (!state.codatMappingOptionsSummary.lastSynced) {
        return (
          <Button
            onClick={() =>
              history.push(
                generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })
              )
            }
          >
            {t('categoriesSubPage.setupSyncBtn')}
          </Button>
        );
      }

      return (
        <Badge
          color="secondary"
          badgeContent={state.codatMappingOptionsSummary?.accounts.newItems}
        >
          <Button
            disabled={!canUser('codat-sync:change')}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                codatSyncDialogOpen: true,
              }))
            }
          >
            {t('categoriesSubPage.editAccSyncBtn')}
          </Button>
        </Badge>
      );
    }

    if (canUser('subcategory:create') && !areItemsReadOnly) {
      return (
        <AddNewItemMenu
          title={t('categoriesSubPage.addButtonTitle')}
          onAdd={() =>
            setState((prevState) => ({
              ...prevState,
              isAddSubcategoryDialogOpen: true,
            }))
          }
          onUpload={() =>
            setState((prevState) => ({
              ...prevState,
              isUploadSubcategoryDialogOpen: true,
            }))
          }
        />
      );
    }

    return null;
  };

  return (
    <>
      {!!subcategories && (
        <>
          <ContentContainer>
            <HeaderContainer>
              <HeaderTitle>
                {t('categoriesSubPage.mainTitle')}

                {state.codatMappingOptionsSummary?.lastSynced && (
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    component="span"
                    ml={1}
                  >
                    (
                    {t('categoriesSubPage.lastSync', {
                      date: moment(
                        state.codatMappingOptionsSummary.lastSynced
                      ).format('DD MMM YYYY, HH:mm'),
                    })}
                    )
                  </Typography>
                )}
              </HeaderTitle>
              <Typography>{renderTitle()}</Typography>
            </HeaderContainer>
          </ContentContainer>

          <FiltersContainer>
            {!state.isLoading && !!subcategories.length && (
              <PageSearchInput
                sx={{ mr: 2 }}
                initialValue={state.search}
                onChange={(value) =>
                  setState((prevState) => ({ ...prevState, search: value }))
                }
                disabled={!filteredCategories.length}
              />
            )}

            {!!subcategories?.length && (
              <StatusFilterSelect
                filterValue={
                  state.activeFilter === 'all' ? '' : state.activeFilter
                }
                onFilterChange={(activeFilter) =>
                  setState((prevState) => ({
                    ...prevState,
                    activeFilter: activeFilter || 'all',
                  }))
                }
              />
            )}

            <Box ml="auto">{renderCustomButton()}</Box>
          </FiltersContainer>

          <Box>
            <TableContainer>
              <Table sx={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('categoriesSubPage.categoriesHeader')}
                    </TableCell>
                    <TableCell>
                      {t('categoriesSubPage.accountNumberHeader')}
                    </TableCell>
                    {canUser('subcategory:update') && !areItemsReadOnly && (
                      <TableCell width={72} />
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!noSearchResults &&
                    merchantCategories.map((category) => {
                      const subcategoriesByCategory =
                        subcategoriesByCategories[category];

                      if (
                        !subcategoriesByCategory ||
                        (state.search && !subcategoriesByCategory?.length)
                      )
                        return null;

                      return (
                        <SubcategoriesTable
                          key={category}
                          category={category}
                          areItemsReadOnly={areItemsReadOnly}
                          subcategoriesByCategory={subcategoriesByCategory}
                          onUpdate={(selectedCategory) =>
                            setState((prevState) => ({
                              ...prevState,
                              isEditSubcategoryDialogOpen: true,
                              editableSubcategory: selectedCategory,
                            }))
                          }
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}

      {!state.isLoading && noSearchResults && (
        <Box position="relative" minHeight={150}>
          <NothingFound />
        </Box>
      )}

      <LoaderWithOverlay loading={state.isLoading} />

      <AddSubCategoryDialog
        open={state.isAddSubcategoryDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAddSubcategoryDialogOpen: false,
          }))
        }
        onSuccess={(subcategory) => {
          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: { subcategories: [...subcategories!, subcategory] },
          });
          setState((prevState) => ({
            ...prevState,
            isAddSubcategoryDialogOpen: false,
          }));
        }}
      />

      <EditSubCategoryDialog
        open={state.isEditSubcategoryDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditSubcategoryDialogOpen: false,
          }))
        }
        onSuccess={(subcategory) => {
          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: {
              subcategories: subcategories!.map((item) =>
                item.id === subcategory.id ? subcategory : item
              ),
            },
          });
          setState((prevState) => ({
            ...prevState,
            isEditSubcategoryDialogOpen: false,
          }));
        }}
        onDelete={(id) => {
          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: {
              subcategories: subcategories!.filter((item) => item.id !== id),
            },
          });
          setState((prevState) => ({
            ...prevState,
            isEditSubcategoryDialogOpen: false,
          }));
        }}
        subcategory={state.editableSubcategory}
      />

      <UploadGLAccountsDialog
        open={state.isUploadSubcategoryDialogOpen}
        onSuccess={(uploadedCategories) => {
          setState((prevState) => ({
            ...prevState,
            isUploadSubcategoryDialogOpen: false,
          }));
          getData();
          enqueueSnackbar(
            t('categoriesSubPage.uploadSuccessMsg', {
              count: uploadedCategories.length,
            })
          );
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUploadSubcategoryDialogOpen: false,
          }))
        }
      />

      <CodatSyncSeparatedDialog
        name={SyncNameEnum.accounts}
        open={state.codatSyncDialogOpen}
        onSuccess={getData}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default withPageConfig(
  withTabPermission(CategoriesSubPage, 'subcategoryEnabled'),
  { permission: 'categories-sub-page:visit' }
);
