import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  MISSING_SUPPLIER_OPTION,
  MISSING_TEAM_OPTION,
  QueryParams,
} from 'domains/transaction/pages/ExportPage/utils';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';
import {
  NONE_VALUE,
  ProjectStatus,
  SubcategoryStatus,
  VatRateStatus,
} from 'services/constants';
import { ProjectData, SupplierData, TeamData, VatRateData } from './useFilters';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  supplierData: SupplierData;
  vatRateData: VatRateData;
  projectData: ProjectData;
  teamData: TeamData;
}

const FilterChips = ({
  params,
  selectedFiltersCount,
  setParam,
  supplierData,
  vatRateData,
  projectData,
  teamData,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { cardAccounts, subcategories, featureModules, accountingSettings },
  } = useGlobalState();
  const isVatRateError = !vatRateData.isLoading && vatRateData.isError;
  const isSupplierError = !supplierData.isLoading && supplierData.isError;
  const isProjectError = !projectData.isLoading && projectData.isError;
  const isTeamError = !teamData.isLoading && teamData.isError;

  const txSimpleTypeChipTooltip = useMemo(() => {
    if (params.type.length < 2) return '';
    return params.type
      .map((item) => t(`transactionSimpleTypes.${item}`))
      .join(', ');
  }, [params.type]);

  const supplierChipText = useMemo(() => {
    if (params.supplier === MISSING_SUPPLIER_OPTION) return '-';
    if (supplierData.isLoading) return t('common.loading');
    if (isSupplierError) return t('filters.notFound');
    if (typeof supplierData.value === 'string') return supplierData.value;
    return supplierData.value?.name || '';
  }, [params.supplier, supplierData]);

  const subcategoryChipText = useMemo(() => {
    if (params.subcategory === SubcategoryStatus.missing) return '-';
    return (
      subcategories?.find((item) => item.id === params.subcategory)?.name || ''
    );
  }, [params.subcategory]);

  const vatRateChipText = useMemo(() => {
    if (params.vatRate === VatRateStatus.missing) return '-';
    if (vatRateData.isLoading) return t('common.loading');
    if (isVatRateError) return t('filters.notFound');
    if (typeof vatRateData.value === 'string') return vatRateData.value;
    return vatRateData.value?.name || '';
  }, [params.vatRate, vatRateData]);

  const projectChipText = useMemo(() => {
    if (params.project === ProjectStatus.missing) return '-';
    if (projectData.isLoading) return t('common.loading');
    if (isProjectError) return t('filters.notFound');
    if (typeof projectData.value === 'string') return projectData.value;
    return projectData.value?.name || '';
  }, [params.vatRate, projectData]);

  const teamChipText = useMemo(() => {
    if (params.team === MISSING_TEAM_OPTION) return '-';
    if (teamData.isLoading) return t('common.loading');
    if (isTeamError) return t('filters.notFound');
    if (typeof teamData.value === 'string') return teamData.value;
    return teamData.value?.name || '';
  }, [params.team, teamData]);

  const periodChipText = useMemo(() => {
    if (!params.fromDate && !params.toDate) return '';
    const array = [];
    if (params.fromDate)
      array.push(moment(params.fromDate).format('D MMM YYYY'));
    if (params.toDate) array.push(moment(params.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [params.fromDate, params.toDate]);

  const reviewStatusChipText = useMemo(() => {
    if (!params.reviewStatus) return '';
    if (params.reviewStatus === NONE_VALUE) return t(`common.none`);
    return t(`transactionReviewStatus.${params.reviewStatus}`);
  }, [params.reviewStatus]);

  const cardAccountsChipText = useMemo(
    () =>
      cardAccounts?.find((account) => account.id === params.cardAccountId)?.name
        ?.value || '',
    [params.cardAccountId, cardAccounts]
  );

  if (!selectedFiltersCount) return null;

  return (
    <PageFilterChips>
      {!!params.type.length && (
        <Tooltip title={txSimpleTypeChipTooltip}>
          <Chip
            label={getFilterChipText(
              t(`transactionSimpleTypes.${params.type[0]}`),
              params.type.length
            )}
            onDelete={() => setParam('type', [])}
          />
        </Tooltip>
      )}

      {(params.fromDate || params.toDate) && (
        <Chip
          label={periodChipText}
          onDelete={() =>
            setParam([
              ['toDate', ''],
              ['fromDate', ''],
            ])
          }
        />
      )}

      {cardAccounts.length > 1 && params.cardAccountId && (
        <Chip
          label={getFilterChipText(
            cardAccountsChipText,
            1,
            t('filters.account')
          )}
          onDelete={() => setParam('cardAccountId', '')}
        />
      )}

      {!!params.supplier && (
        <Chip
          label={getFilterChipText(supplierChipText, 1, t('filters.supplier'))}
          color={isSupplierError ? 'error' : 'default'}
          disabled={supplierData.isLoading}
          onDelete={() => setParam('supplier', '')}
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && !!params.receipt && (
        <Chip
          label={getFilterChipText(
            t(`transactionReceiptStatuses.${params.receipt}`),
            1,
            t('filters.receipts')
          )}
          onDelete={() => setParam('receipt', '')}
        />
      )}

      {accountingSettings?.subcategoryEnabled &&
        !!params.subcategory &&
        !!subcategories?.length && (
          <Chip
            label={getFilterChipText(
              subcategoryChipText,
              1,
              t('filters.subcategory')
            )}
            onDelete={() => setParam('subcategory', '')}
          />
        )}

      {!!params.vatRate && (
        <Chip
          label={getFilterChipText(vatRateChipText, 1, t('filters.vatRate'))}
          color={isVatRateError ? 'error' : 'default'}
          disabled={vatRateData.isLoading}
          onDelete={() => setParam('vatRate', '')}
        />
      )}

      {!!params.team && (
        <Chip
          label={getFilterChipText(teamChipText, 1, t('filters.team'))}
          color={isTeamError ? 'error' : 'default'}
          disabled={teamData.isLoading}
          onDelete={() => setParam('team', '')}
        />
      )}

      {!!params.project && (
        <Chip
          label={getFilterChipText(projectChipText, 1, t('filters.project'))}
          color={isProjectError ? 'error' : 'default'}
          disabled={projectData.isLoading}
          onDelete={() => setParam('project', '')}
        />
      )}

      {params.reviewStatus && (
        <Chip
          label={reviewStatusChipText}
          onDelete={() =>
            setParam([
              ['reviewStatus', ''],
              ['flagReason', ''],
            ])
          }
        />
      )}

      {params.flagReason && (
        <Chip
          label={t(`reviewFlagReasons.${params.flagReason}`)}
          onDelete={() => setParam('flagReason', '')}
        />
      )}
    </PageFilterChips>
  );
};

export default FilterChips;
