import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingSettings,
  UpdateAccountingGeneralAccountsPayload,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const getDefaultEmployeeCreditorAccountPayload = (
  initialValue: AccountingSettings['defaultEmployeeCreditorAccount'],
  accountNumber: string
): UpdateAccountingGeneralAccountsPayload['defaultEmployeeCreditorAccount'] => {
  if (initialValue) {
    return {
      id: initialValue.id,
      accountNumber: accountNumber || initialValue.accountNumber,
    };
  }
  if (accountNumber) {
    return { accountNumber };
  }
  return null;
};

const NumberFormatProps = {
  allowLeadingZeros: true,
  customInput: TextField,
  allowNegative: false,
  decimalScale: 0,
};

interface Props extends DialogProps {
  onClose: () => void;
}

const GeneralAccountsChangeDialog = (props: Props) => {
  const partnerName = usePartnerName();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings, cardAccounts },
  } = useGlobalState();
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      pliantCreditCardAccount:
        accountingSettings!.pliantCreditCardAccount?.toString() || '',
      defaultSupplierAccount:
        accountingSettings!.defaultSupplierAccount?.toString() || '',
      cashInTransitAccount:
        accountingSettings!.cashInTransitAccount?.toString() || '',
      defaultEmployeeCreditorAccount:
        accountingSettings!.defaultEmployeeCreditorAccount?.accountNumber || '',
    },
    validate(values) {
      const errors: { [key in keyof typeof values]?: string } = {};

      const generalLedgerAccLen = accountingSettings!
        .generalLedgerAccountLength;
      if (!generalLedgerAccLen) return;

      const pliantCreditCardAccLen = values.pliantCreditCardAccount.length;
      if (
        pliantCreditCardAccLen &&
        (pliantCreditCardAccLen < generalLedgerAccLen ||
          pliantCreditCardAccLen > generalLedgerAccLen + 1)
      ) {
        errors.pliantCreditCardAccount = t(
          'datevExportSettingsGroup.strLenIsNotBetweenError',
          {
            min: generalLedgerAccLen,
            max: generalLedgerAccLen + 1,
          }
        );
      }
      if (
        accountingSettings!.useSupplierAccount &&
        values.defaultSupplierAccount.length &&
        values.defaultSupplierAccount.length !== generalLedgerAccLen + 1
      ) {
        errors.defaultSupplierAccount = t(
          'datevExportSettingsGroup.strLenIsNotEqualToError',
          { length: generalLedgerAccLen + 1 }
        );
      }
      if (
        values.cashInTransitAccount &&
        values.cashInTransitAccount.length !== generalLedgerAccLen
      ) {
        errors.cashInTransitAccount = t(
          'datevExportSettingsGroup.strLenIsNotEqualToError',
          { length: generalLedgerAccLen }
        );
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const updatedAccountingSettings = await api.updateAccountingGeneralAccounts(
          {
            organizationId: organization!.id,
            pliantCreditCardAccount:
              values.pliantCreditCardAccount.trim() || null,
            useSupplierAccount: accountingSettings!.useSupplierAccount,
            defaultSupplierAccount:
              values.defaultSupplierAccount.trim() || null,
            cashInTransitAccount: values.cashInTransitAccount.trim() || null,
            defaultEmployeeCreditorAccount: getDefaultEmployeeCreditorAccountPayload(
              accountingSettings!.defaultEmployeeCreditorAccount,
              values.defaultEmployeeCreditorAccount.trim()
            ),
          }
        );
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings: updatedAccountingSettings },
        });
        if (!mounted.current) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('datevExportSettingsGroup.generalAccounts')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="general-accounts-change-form"
          noValidate
        >
          <Grid container rowSpacing={2}>
            {cardAccounts.length < 2 && (
              <Grid item xs={12}>
                <NumberFormat
                  label={
                    <>
                      {t('datevExportSettingsGroup.pliantCreditCardAccount', {
                        partnerName,
                      })}
                      <InputLabelTooltipIcon
                        title={t(
                          'datevExportSettingsGroup.pliantCreditCardAccountTitle',
                          {
                            partnerName,
                          }
                        )}
                      />
                    </>
                  }
                  name="pliantCreditCardAccount"
                  value={formik.values.pliantCreditCardAccount}
                  onBlur={formik.handleBlur}
                  onValueChange={(values) => {
                    formik.setFieldValue(
                      'pliantCreditCardAccount',
                      values.value,
                      false
                    );
                    formik.setFieldError('pliantCreditCardAccount', undefined);
                  }}
                  disabled={formik.isSubmitting}
                  error={!!formik.errors.pliantCreditCardAccount}
                  helperText={
                    !!formik.errors.pliantCreditCardAccount &&
                    formik.errors.pliantCreditCardAccount
                  }
                  {...NumberFormatProps}
                />
              </Grid>
            )}

            {accountingSettings!.useSupplierAccount && (
              <Grid item xs={12}>
                <TextField
                  name="defaultSupplierAccount"
                  label={
                    <>
                      {t('datevExportSettingsGroup.defaultSupplierAccount')}
                      <InputLabelTooltipIcon
                        title={t(
                          'datevExportSettingsGroup.defaultSupplierAccountTitle',
                          {
                            partnerName,
                          }
                        )}
                      />
                    </>
                  }
                  value={formik.values.defaultSupplierAccount}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'defaultSupplierAccount',
                      e.target.value,
                      false
                    );
                    formik.setFieldError('defaultSupplierAccount', undefined);
                  }}
                  disabled={formik.isSubmitting}
                  error={!!formik.errors.defaultSupplierAccount}
                  helperText={formik.errors.defaultSupplierAccount}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <NumberFormat
                name="cashInTransitAccount"
                label={
                  <>
                    {t('datevExportSettingsGroup.cashInTransitAccount')}
                    <InputLabelTooltipIcon
                      title={t(
                        'datevExportSettingsGroup.cashInTransitAccountTitle',
                        {
                          partnerName,
                        }
                      )}
                    />
                  </>
                }
                value={formik.values.cashInTransitAccount}
                onBlur={formik.handleBlur}
                onValueChange={(values) => {
                  formik.setFieldValue(
                    'cashInTransitAccount',
                    values.value,
                    false
                  );
                  formik.setFieldError('cashInTransitAccount', undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.cashInTransitAccount}
                helperText={
                  !!formik.errors.cashInTransitAccount &&
                  formik.errors.cashInTransitAccount
                }
                {...NumberFormatProps}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="defaultEmployeeCreditorAccount"
                label={
                  <>
                    {t(
                      'datevExportSettingsGroup.defaultEmployeeCreditorAccount'
                    )}
                    <InputLabelTooltipIcon
                      title={t(
                        'datevExportSettingsGroup.defaultEmployeeCreditorAccountTitle'
                      )}
                    />
                  </>
                }
                value={formik.values.defaultEmployeeCreditorAccount}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(
                    'defaultEmployeeCreditorAccount',
                    e.target.value,
                    false
                  );
                }}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting}
          type="submit"
          form="general-accounts-change-form"
        >
          {t('datevExportSettingsGroup.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(GeneralAccountsChangeDialog);
