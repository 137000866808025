import { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Box,
  Button,
  CopyIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InfoIcon,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import { Tab, Tabs } from 'layout';
import {
  CardAccount,
  CardAccountCurrency,
  ProcessingAccount,
  ProcessingAccountTransferType,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

// change `sortCode` from `123456` to `12-34-56`
const updateSortCodeFormat = (sortCode: string) => {
  return sortCode.match(/.{1,2}/g)?.join('-') || '';
};

const getReference = (country: SupportedCountry, partnerName: string) => {
  // hardcoded strings based on organization's country
  return country === SupportedCountry.de || country === SupportedCountry.at
    ? `${partnerName} Kontoaufladung`
    : `${partnerName} Top Up`;
};

const getBankInfo = (
  partnerName: string,
  processingAccount: ProcessingAccount,
  country: SupportedCountry
) =>
  `
${processingAccount.beneficiary}
${processingAccount.bankName}
${processingAccount.iban}
${processingAccount.bic}
${getReference(country, partnerName)}`.trim();

const getBankInfoForLocalTransferTypeInGBP = (
  partnerName: string,
  processingAccount: ProcessingAccount,
  country: SupportedCountry
) =>
  `
${processingAccount.beneficiary}
${processingAccount.accountNumber}
${updateSortCodeFormat(processingAccount.sortCode)}
${processingAccount.bankName}
${getReference(country, partnerName)}`.trim();

const getSelectedProcessingAccount = (
  processingAccounts: ProcessingAccount[],
  transferType: ProcessingAccountTransferType
) => {
  if (processingAccounts.length > 1) {
    return processingAccounts.find(
      (item) => item.transferType === transferType
    );
  }
  return processingAccounts[0];
};

interface BaseProps extends DialogProps {
  onClose: () => void;
}

interface PropsWithVisibleCardAccounts extends BaseProps {
  visibleCardAccounts: CardAccount[];
  selectedCardAccount?: undefined;
}
interface PropsWithSelectedCardAccount extends BaseProps {
  selectedCardAccount: CardAccount | null;
  visibleCardAccounts?: undefined;
}
type Props = PropsWithVisibleCardAccounts | PropsWithSelectedCardAccount;

const TopUpAccountDialog = ({
  visibleCardAccounts,
  selectedCardAccount,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const getCardAccountName = useCardAccountNameGetter();
  const partnerName = usePartnerName();
  const {
    state: { organization, cardAccounts, defaultCardAccount },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const [transferType, setTransferType] = useState<
    ProcessingAccountTransferType
  >(ProcessingAccountTransferType.local);
  const [processingAccounts, setProcessingAccounts] = useState<
    ProcessingAccount[]
  >([]);
  const [cardAccountId, setCardAccountId] = useState<string>(
    selectedCardAccount?.id || defaultCardAccount!.id
  );
  const selectedProcessingAccount = getSelectedProcessingAccount(
    processingAccounts,
    transferType
  );

  const getData = async () => {
    setIsLoading(true);
    try {
      const { processingAccounts } = await api.getProcessingAccounts(
        organization!.id,
        cardAccountId
      );
      if (!mounted.current) return;
      setProcessingAccounts(processingAccounts);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [cardAccountId]);

  const onCopy = () =>
    enqueueSnackbar(t('topUpAccountDialog.itemCopiedMessage'), {
      variant: 'success',
    });

  const renderTitle = useCallback(() => {
    if (selectedCardAccount) {
      const name = getCardAccountName(selectedCardAccount);
      return t('topUpAccountDialog.titleWithCardAccountName', { name });
    }
    return t('topUpAccountDialog.title');
  }, [getCardAccountName]);

  const renderTable = () => {
    if (!selectedProcessingAccount) return null;

    const selectedCurrency = (
      selectedCardAccount ||
      visibleCardAccounts?.find((item) => item.id === cardAccountId)
    )?.currency.value;

    const isLocalTransferTypeInGBP =
      selectedProcessingAccount.transferType ===
        ProcessingAccountTransferType.local &&
      selectedCurrency === CardAccountCurrency.GBP;

    const rows: { key: string; text: string }[] = isLocalTransferTypeInGBP
      ? [
          { key: 'beneficiary', text: selectedProcessingAccount.beneficiary },
          {
            key: 'accountNumber',
            text: selectedProcessingAccount.accountNumber,
          },
          {
            key: 'sortCode',
            text: updateSortCodeFormat(selectedProcessingAccount.sortCode),
          },
          { key: 'bank', text: selectedProcessingAccount.bankName },
          {
            key: 'reference',
            text: getReference(organization!.country, partnerName!),
          },
        ]
      : [
          { key: 'beneficiary', text: selectedProcessingAccount.beneficiary },
          { key: 'bank', text: selectedProcessingAccount.bankName },
          { key: 'iban', text: selectedProcessingAccount.iban },
          { key: 'bic', text: selectedProcessingAccount.bic },
          {
            key: 'reference',
            text: getReference(organization!.country, partnerName!),
          },
        ];

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                {processingAccounts.length === 1 &&
                  t('topUpAccountDialog.viaBankTransfer')}
              </TableCell>
              <TableCell colSpan={2} align="right">
                <CopyToClipboard
                  onCopy={onCopy}
                  text={
                    isLocalTransferTypeInGBP
                      ? getBankInfoForLocalTransferTypeInGBP(
                          partnerName!,
                          selectedProcessingAccount,
                          organization!.country
                        )
                      : getBankInfo(
                          partnerName!,
                          selectedProcessingAccount,
                          organization!.country
                        )
                  }
                >
                  <Button
                    startIcon={<CopyIcon />}
                    variant="text"
                    size="small"
                    sx={{ textWrap: 'nowrap' }}
                  >
                    {t('topUpAccountDialog.copyAll')}
                  </Button>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ key, text }) => (
              <TableRow key={key}>
                <TableCell sx={{ width: 1 / 4 }}>
                  {t(`topUpAccountDialog.${key}`)}
                </TableCell>
                <TableCell colSpan={2} sx={{ color: 'text.secondary' }}>
                  {text}
                </TableCell>
                <TableCell align="right">
                  <CopyToClipboard text={text} onCopy={onCopy}>
                    <IconButton size="small">
                      <CopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderProcessingAccountInfo = () => {
    return processingAccounts.length > 1 ? (
      <>
        <Tabs
          value={transferType}
          onChange={(e, newValue) => setTransferType(newValue)}
        >
          <Tab
            value={ProcessingAccountTransferType.local}
            label={
              <>
                {t('topUpAccountDialog.localTitle')}
                <Tooltip title={t('topUpAccountDialog.localTooltip')}>
                  <InfoIcon
                    sx={{
                      width: 20,
                      height: 20,
                      ml: 0.75,
                      color: 'text.secondary',
                    }}
                  />
                </Tooltip>
              </>
            }
            sx={(theme) => ({
              flex: `0 0 calc(50% - ${theme.spacing(2)})`,
              flexDirection: 'row',
            })}
          />
          <Tab
            value={ProcessingAccountTransferType.swift}
            label={
              <>
                {t('topUpAccountDialog.swiftTitle')}
                <Tooltip title={t('topUpAccountDialog.swiftTooltip')}>
                  <InfoIcon
                    sx={{
                      width: 20,
                      height: 20,
                      ml: 0.75,
                      color: 'text.secondary',
                    }}
                  />
                </Tooltip>
              </>
            }
            sx={(theme) => ({
              flex: `0 0 calc(50% - ${theme.spacing(2)})`,
              flexDirection: 'row',
              ml: 4,
            })}
          />
        </Tabs>
        <Divider sx={{ position: 'relative', top: -1 }} />

        {renderTable()}
      </>
    ) : (
      renderTable()
    );
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{renderTitle()}</DialogTitle>
      <DialogContent data-intercom-target="top-up-account-dialog-content">
        <Typography variant="body2" mb={1}>
          {t('topUpAccountDialog.description1')}
        </Typography>

        <Typography variant="body2" component="div" mb={1}>
          <ul>
            <li>
              <Trans
                i18nKey="topUpAccountDialog.description2"
                components={{ b: <b /> }}
              />
            </li>
            <li>{t('topUpAccountDialog.description3')}</li>
          </ul>
        </Typography>
        <Typography variant="body2" component="div" mb={4}>
          {t('topUpAccountDialog.description4')}
        </Typography>

        {cardAccounts.length > 1 &&
          !!visibleCardAccounts &&
          !selectedCardAccount && (
            <FormControl fullWidth sx={{ mb: 4 }}>
              <InputLabel>
                {t('topUpAccountDialog.cardAccountLabel')}
              </InputLabel>
              <Select<string>
                value={cardAccountId}
                onChange={(e) => setCardAccountId(e.target.value)}
              >
                {visibleCardAccounts.map((account) => (
                  <MenuItem value={account.id} key={account.id}>
                    {getCardAccountName(account)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

        <Box position="relative" sx={{ minHeight: 200 }}>
          {renderProcessingAccountInfo()}
          <LoaderWithOverlay loading={isLoading} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          data-intercom-target="top-up-account-dialog-submit-button"
        >
          {t('topUpAccountDialog.closeBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(TopUpAccountDialog);
