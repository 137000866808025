import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { CardIconWithDetails } from 'domains/card/components';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  IntegrationCell,
  MerchantCell,
  TransactionAmountCell,
  TransactionMissingReceiptCell,
  TransactionReviewStatusIcon,
} from 'domains/transaction/components';
import {
  drawerPlaceholderColumn,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
} from 'elements';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import { Transaction } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const {
    state: { featureModules, organization, cardAccounts },
  } = useGlobalState();
  const isDetailsPageOpen = useIsDetailsPageOpen('/:transactionId');
  const getCardAccountName = useCardAccountNameGetter();

  return useMemo(() => {
    const columns: GridColDef<Transaction>[] = [
      {
        headerName: t('transactionsNeedsReviewPage.merchant'),
        field: 'merchantName',
        sortable: false,
        flex: 1.5,
        minWidth: 60,
        renderCell: ({ row }) => (
          <MerchantCell transaction={row} showReceiptIcon showCommentIcon />
        ),
      },
      ...(featureModules.RECEIPT_MANAGEMENT &&
      organization?.missingReceiptNotificationEnabled
        ? [
            {
              headerName: '',
              field: 'receiptNeeded',
              align: 'center' as GridAlignment,
              headerAlign: 'center' as GridAlignment,
              sortable: false,
              flex: 0.2,
              renderCell: ({ row }: GridRenderCellParams<Transaction>) => (
                <TransactionMissingReceiptCell transaction={row} />
              ),
            },
          ]
        : []),
      {
        headerName: t('transactionsNeedsReviewPage.date'),
        field: 'createdAt',
        sortable: false,
        flex: 1,
        valueGetter: ({ row }) => moment(row.createdAt).format('D MMM YYYY'),
      },
      {
        headerName: t('transactionsNeedsReviewPage.card'),
        field: 'cardType',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <CardIconWithDetails
            cardConfig={row.cardConfig}
            cardDesignId={row.cardDesignId}
            cardRefNum={row.cardRefNum}
            cardName={row.cardName}
            showCardNameTooltip
          />
        ),
      },
      {
        headerName: t('transactionsNeedsReviewPage.member'),
        field: 'memberFirstName',
        sortable: false,
        flex: 0.8,
        valueGetter: ({ row }) =>
          getFullName(row.memberFirstName, row.memberLastName) || '-',
      },
      {
        headerName: t('transactionsNeedsReviewPage.team'),
        field: 'teamName',
        flex: 0.8,
        sortable: false,
      },
      ...(cardAccounts.length > 1
        ? [
            {
              headerName: t('transactionsNeedsReviewPage.account'),
              field: 'cardAccountName',
              sortable: false,
              flex: 0.8,
              valueGetter: ({ row }: GridRenderCellParams<Transaction>) =>
                getCardAccountName(row.cardAccountId),
            },
          ]
        : []),
      {
        headerName: t('transactionsNeedsReviewPage.amount'),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 0.8,
        renderCell: ({ row }) => (
          <TransactionAmountCell
            transaction={row}
            bookingDate={row.bookingDate}
          />
        ),
      },
      {
        headerName: t('transactionsNeedsReviewPage.review'),
        field: 'review',
        sortable: false,
        flex: 0.4,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <TransactionReviewStatusIcon status={row.reviewStatus} />
        ),
      },
      {
        headerName: t('transactionsNeedsReviewPage.export'),
        field: '_integration_',
        sortable: false,
        flex: 0.4,
        minWidth: 80,
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment,
        renderCell: ({ row }: GridRenderCellParams<Transaction>) => (
          <IntegrationCell
            status={row.status}
            exportStatus={row.exportStatus}
            integrationType={row.integrationType}
          />
        ),
      },
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [i18n.language, isDetailsPageOpen, getCardAccountName]);
};

export default useColumns;
