import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { TopUpAccountDialog } from 'domains/billing/dialogs';
import { useIsAllowedToTopUpCardAccount } from 'domains/billing/hooks';
import CustomBar from 'domains/organization/pages/DashboardPage/FinancialOverview/CustomBar';
import {
  Box,
  Button,
  IconButton,
  InfoIcon,
  Link,
  PencilSimpleIcon,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import { Money, OrganizationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';
import ChangeExpectedMonthlySpendDialog from './ChangeExpectedMonthlySpendDialog';

const isPrefundOrgBalanceLow = (
  balance: Money | null,
  expectedMonthlySpend: Money | null
) => {
  if (!balance || !expectedMonthlySpend) return false;
  return (balance.value / expectedMonthlySpend.value) * 100 <= 20;
};

interface State {
  expectedMonthlySpend: Money | null;
  isLoading: boolean;
  error: unknown;
  isTopUpDialogOpen: boolean;
  isSpendDialogOpen: boolean;
}

const SinglePrefundedAccountContent = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    state: { cardAccounts, organization },
  } = useGlobalState();
  const isAllowedToTopUpCardAccount = useIsAllowedToTopUpCardAccount();
  const [state, setState] = useState<State>({
    expectedMonthlySpend: null,
    isLoading: true,
    error: null,
    isTopUpDialogOpen: false,
    isSpendDialogOpen: false,
  });
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    organization!.status,
    OrganizationStatus.onboardingRiskReview
  );
  const cardAccountsForTopUp = cardAccounts.filter(isAllowedToTopUpCardAccount);
  const balance = cardAccounts[0].balance.value;
  const availableLimit = cardAccounts[0].availableLimit.value;
  const limit = cardAccounts[0].creditLimit.value;

  const getData = async () => {
    try {
      const { expectedMonthlySpend } = await api.getExpectedMonthlySpend(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        expectedMonthlySpend,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, error, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    if (
      didOrgPassOnboardingRisk &&
      canUser('org-expected-monthly-spend:view')
    ) {
      getData();
    } else {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, []);

  const getRemainingFundsInDays = () => {
    return Math.round((balance.value * 30) / state.expectedMonthlySpend!.value);
  };

  const getCoverage = () => {
    return Math.round(
      (balance.value / state.expectedMonthlySpend!.value) * 100
    );
  };

  if (state.isLoading) {
    // Mimic the loading state of the parent component
    // but without a spinner to avoid flickering
    return (
      <>
        <Typography variant="subtitle1" flexGrow={1} py="1px" mb={2}>
          {t('dashboardPage.availableFundsAndLimit')}
        </Typography>
        <Box minHeight={60} mb={2} />
      </>
    );
  }

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
        <Typography variant="subtitle1" flexGrow={1}>
          {t('dashboardPage.availableFundsAndLimit')}
        </Typography>
        {didOrgPassOnboardingRisk && (
          <>
            {state.expectedMonthlySpend && (
              <Typography variant="caption2" color="text.secondary" mx={1}>
                {t('dashboardPage.expectedRemainingFundsInDays', {
                  count: getRemainingFundsInDays(),
                })}
              </Typography>
            )}
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isTopUpDialogOpen: true,
                }))
              }
              disabled={!cardAccountsForTopUp.length}
            >
              {t('dashboardPage.topUpAccount')}
            </Button>
          </>
        )}
      </Box>

      <Box mb={2}>
        <Typography
          variant="caption2"
          component="div"
          color="text.secondary"
          mb={0.5}
        >
          {t('dashboardPage.available')}
        </Typography>
        <Box display="flex" alignItems="flex-end" mb={1}>
          <Typography
            variant="h5"
            color={
              isPrefundOrgBalanceLow(balance, state.expectedMonthlySpend)
                ? 'warning.main'
                : 'text.primary'
            }
          >
            {didOrgPassOnboardingRisk ? <FormatMoney value={balance} /> : '-'}
          </Typography>
          {didOrgPassOnboardingRisk && state.expectedMonthlySpend && (
            <>
              <Typography
                variant="caption"
                mr={0.5}
                mb="2px"
                ml={1}
                color="text.secondary"
              >
                <Trans
                  i18nKey="dashboardPage.coverage"
                  values={{ value: getCoverage() }}
                />
              </Typography>
              <Tooltip title={t('dashboardPage.coveragePrefundTooltip')}>
                <InfoIcon
                  fontSize="small"
                  sx={{ mb: '2px', color: 'text.secondary' }}
                />
              </Tooltip>
            </>
          )}
        </Box>

        {state.error ? (
          <Typography variant="caption" color="error.main">
            {t('errors.loadData')}
          </Typography>
        ) : state.expectedMonthlySpend ? (
          <>
            <CustomBar
              balance={balance}
              availableLimit={availableLimit}
              limit={limit}
              expectedMonthlySpend={state.expectedMonthlySpend}
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mt={0.5}
            >
              <IconButton
                size="small"
                disabled={!canUser('org-expected-monthly-spend:change')}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isSpendDialogOpen: true,
                  }))
                }
              >
                <PencilSimpleIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
              <Typography variant="caption2" color="text.secondary" ml={0.5}>
                <Trans
                  i18nKey="dashboardPage.expectedMonthlySpending"
                  components={{
                    value: <FormatMoney value={state.expectedMonthlySpend} />,
                  }}
                />
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Link
              variant="caption"
              component="button"
              sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
              disabled={!canUser('org-expected-monthly-spend:change')}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isSpendDialogOpen: true,
                }))
              }
            >
              {t('dashboardPage.addYourExpMonthlySpendingBtn')}
              <PencilSimpleIcon sx={{ width: 16, height: 16, ml: 1 }} />
            </Link>
            <Typography
              variant="caption2"
              component="div"
              color="text.secondary"
            >
              {t('dashboardPage.addYourExpMonthlySpendingDescription')}
            </Typography>
          </>
        )}
      </Box>

      <TopUpAccountDialog
        open={state.isTopUpDialogOpen}
        onClose={() =>
          setState((prevState) => ({ ...prevState, isTopUpDialogOpen: false }))
        }
        visibleCardAccounts={cardAccountsForTopUp}
      />

      <ChangeExpectedMonthlySpendDialog
        open={state.isSpendDialogOpen}
        expectedMonthlySpend={state.expectedMonthlySpend}
        onSuccess={(expectedMonthlySpend) => {
          setState((prevState) => ({
            ...prevState,
            expectedMonthlySpend,
            isSpendDialogOpen: false,
          }));
        }}
        onClose={() =>
          setState((prevState) => ({ ...prevState, isSpendDialogOpen: false }))
        }
      />
    </>
  );
};

export default SinglePrefundedAccountContent;
